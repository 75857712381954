
// $textColor: red;
// $textHoverColor: saddlebrown;
// $navTextColor: #1776e1;
// $navCollapseBg: #3588cb;
// $hover: #7d0000; 
// $itemNavBg: #000243;
// $primary4: #eb6100; 

@import "@/assets/style/mixin.scss";


// 导航颜色定制
// 默认进入
$default: (
    navText: #FFFFFF,
    navCollapseBg: transparent,
    linkHover: #FFFFFF,
    navItemBg: transparent,
	logoImg:'~@/assets/imgs/white-logo.png',
	navIcon: '~@/assets/imgs/icon/nav-toggle-white.png',
	linkTextColor: #FFFFFF,
);

// 滚动到banner以外的白底色上的样式
$hhai: (
    navText: #22222B,
    navCollapseBg: #FFFFFF,
    linkHover: #22222B,
    navItemBg: #FFFFFF,
	logoImg:'~@/assets/imgs/black-logo.png',
	navIcon: '~@/assets/imgs/icon/nav-toggle-black.png'
);


// // 小屏&移动端出现折叠框样式
$small: (
    navText: #FFFFFF,
    navCollapseBg: #22222B,
    linkHover: #FFFFFF,
    navItemBg: #22222B,
	logoImg:'~@/assets/imgs/white-logo.png',
	navIcon: '~@/assets/imgs/icon/nav-toggle-white.png'
);

// // 小屏&移动端出现折叠框样式-滚动到banner以外的白底色上的样式
$smallHhai: (
    navText: #FFFFFF,
    navCollapseBg: #22222B,
    linkHover: #FFFFFF,
    navItemBg: #22222B,
	logoImg:'~@/assets/imgs/white-logo.png',
	navIcon: '~@/assets/imgs/icon/nav-toggle-white.png'
);

.layout-head-nav {
	width: 100%;
	box-sizing: border-box;
	font-family: PingFangSC-Regular, PingFang SC;
	.logo-link{
		line-height: 0;
	}
	.logo-image {
		position: relative;
		width: 7.31rem;
		height: 2.25rem;
		display: inline-block;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 7.31rem;
			height: 2.25rem;
			background: url(map-get($default,logoImg)) no-repeat center center;
			background-size: cover;
		}
	}
	.navbar-nav {
		strong {
			font-size: 15px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
		}
	}
}

//  默认 透明背景||深色背景nav样式
.default_white_style{
	@include navAcitveStyle(
		map-get($default,navText),
		map-get($default,navCollapseBg),
		map-get($default,linkHover),
		map-get($default,navItemBg),
	);

	.blink-arrow{
		// width: 1.5rem;
		// height: 1.5rem;
		// display: inline-block;
		// color: green !important;
		opacity: 0;
	}


	.logo-image::before{
		background: url(map-get($default,logoImg)) no-repeat center center;
		background-size: cover;
	}
	.dropdown .dropdown-toggle {
		@include arrowLine(map-get($default,navText));
	}
	.dropdown.show .dropdown-toggle {
		@include arrowLine(map-get($default,navText), top);
	}
	// 折叠按钮icon
	.navbar-toggler .navbar-toggler-icon {
		background-image: url(map-get($default,navIcon));
	}
}

// 默认 nav透明背景（黑色字体与图标）:  对应导航样式
.layout-head-nav{
	@extend .default_white_style;
}


// nav 白底色（黑色字体与图标）:  对应导航样式
.hhai_nav.layout-head-nav{
	background: #fff;
	@include navAcitveStyle(
		map-get($hhai,navText),
		map-get($hhai,navCollapseBg),
		map-get($hhai,linkHover),
		map-get($hhai,navItemBg),
	);
	.logo-image::before{
		background: url(map-get($hhai,logoImg)) no-repeat center center;
		background-size: cover;
	}
	.dropdown .dropdown-toggle {
		@include arrowLine(map-get($hhai,navText));
	}
	.dropdown.show .dropdown-toggle {
		@include arrowLine(map-get($hhai,navText), top);
	}
	// 折叠按钮icon
	.navbar-toggler-icon {
		background-image: url(map-get($hhai,navIcon));
	}
}

// nav 白底色（黑色字体与图标）:  对应导航样式
.hhai_transparent_nav.layout-head-nav{
	@include navAcitveStyle(
		map-get($hhai,navText),
		map-get($default,navCollapseBg),
		map-get($hhai,linkHover),
		map-get($default,navItemBg),
	);
	.logo-image::before{
		background: url(map-get($hhai,logoImg)) no-repeat center center;
		background-size: cover;
	}
	.dropdown .dropdown-toggle {
		@include arrowLine(map-get($hhai,navText));
	}
	.dropdown.show .dropdown-toggle {
		@include arrowLine(map-get($hhai,navText), top);
	}
	// 折叠按钮icon
	.navbar-toggler-icon {
		background-image: url(map-get($hhai,navIcon));
	}
}

/* ----------------------- <992屏幕(pc&移动端) ----------------------- */

@media (max-width: 991.98px) {
	// <992屏幕(pc&移动端): 对应导航样式(固定的)
	.layout-head-nav.topnav.navbar.navbar-expand-lg.navbar-light{
		.navbar-collapse.collapse .nav-item{
			background: map-get($small,navItemBg);
		}
		.navbar-collapse.collapse.show{
			background: map-get($small,navCollapseBg);
		}
	}
	
	
	// <992屏幕(pc&移动端): nav白底色（黑色字体与图标）:  对应导航样式
	.hhai_nav.layout-head-nav{
		background: #fff;
		@include navAcitveStyle(
			map-get($smallHhai,navText),
			map-get($smallHhai,navCollapseBg),
			map-get($smallHhai,linkHover),
			map-get($smallHhai,navItemBg),
		);
	}
	// 导航折叠框内样式（内外背景一致, 使用白底字体）
	.layout-head-nav.topnav.navbar.navbar-expand-lg.navbar-light{
		height: auto;
		display: block;
		.navbar-collapse.collapse.show{
			margin-top: 2.2rem;
		}
		.nav-item{
			text-align: right;
			padding-top: 2.5rem;
			padding-right: 1rem;
			margin-left: 0;
			.nav-link{
				padding-top: 0;
				padding-bottom: 0;
				font-size: 1.25rem;
				text-decoration: underline solid #FFFFFF 1px;
				text-underline-offset: 5px;
				display: flex;
				justify-content: space-between;
			}
			.blink-arrow{
				width: 1.5rem;
				height: 1.5rem;
				display: inline-block;
				color: #FFFFFF;
				opacity: 1;

			}
			.dropdown-toggle.nav-link{
				&:after{
					content: none !important;
				}
			}
		}
		.nav-item .dropdown-menu{
			width: 100%;
			padding-top: .3rem;
			padding-right:0;
			>li{
				padding-top: .7rem;
				padding-bottom: 0 !important;
				.nav-link{
					font-size: .88rem;
					text-align: right;
					display: block;
				}
			}
		}
	
		@include navAcitveStyle(
			map-get($small,navText),
			map-get($small,navCollapseBg),
			map-get($small,linkHover),
			map-get($small,navItemBg),
		);
		&.nav-coustom-folded{
			background: #22222B;
			height: 100%;
			transition: height .5s;
			// logo
			.logo-image::before{
				background: url(map-get($small,logoImg)) no-repeat center center;
				background-size: cover;
			}		
			// 折叠按钮icon
			.navbar-toggler-icon {
				width: 1.5rem;
				height: 1.5rem;
				background-image: url('~@/assets/imgs/icon/nav-close.png');
			}
		}
	}
}
















.app-small .layout-head-nav .logo-image{
	width: 7.88rem;
	height: 1.88rem;
	&:before{
		width: 7.88rem;
		height: 1.88rem;
	}
}

/* 页面大于992 */
@media (min-width: 992px) {
	.layout-head-nav { }
}

/* 当页面宽度大于320px小于992px */
@media screen and (min-width: 300px) and (max-width: 992px) { }

