@import './mixin.scss';
:root {
  --blue: #1300FF;
}
$primary: red;
$theme-colors: (
  "primary":    $primary,
  //   "secondary":  $secondary,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
//   "dark":       $dark
);

a {
  color: #1300FF;
  &:hover{
    color: #1300FF;
  }
}
.btn-link:hover {
  color: #1300FF;
  // text-decoration: underline;
}

.page-link:hover {
  color: #1300FF;
}
a.text-primary:hover, a.text-primary:focus {
  color: #1300FF !important;

}






// --------------------- 导航栏
.navbar.layout-head-nav{
  padding-top: .88rem;
	// padding-bottom: 1.2rem;
	padding-bottom: .88rem;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

// 宽屏默认静态
.navbar-light .navbar-nav .nav-item {
  margin-left: 1rem;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: .94rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-family: PingFangSC-Medium, PingFang SC;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .nav-link:hover{
  text-decoration: underline;
  text-underline-offset: 2px;
}
.navbar-toggler{
  border: 0;
  outline: 0;
  padding: 0.31rem 0.31rem;
  margin-right: 1.7rem;
  line-height: .88rem;
}
.navbar-toggler .navbar-toggler-icon{
  width: 1.13rem;
  height: .88rem;
}
// 折叠导航按钮
.navbar-toggler { }
// 折叠列表
.navbar-collapse { }



.navbar-collapse.collapsing {
  position: relative;
  height: initial;
  overflow:visible;
  transition: none !important;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: initial;
  height: initial;
  transition: none !important;

}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none !important;
  }
}




.navbar-collapse.collapse.show{ 
  .navbar &{
  }
}
.navbar-collapse.collapse.show .navbar-collapse-btn{
    font-weight: 100;
    color: #fff;
    text-shadow: none;
    opacity: 1;

    position: absolute;
    top: 1.25rem;
    right: 2rem;
 }
.navbar-collapse.collapse.show .navbar-nav{

}
.navbar-collapse.collapse.show .nav-item{}

.navbar-nav .dropdown-menu{
  // right: 0;
  left: 0;
  background: transparent;
  border: 0;
  width: 5.8rem;
  min-width: 6rem;
  padding: 0 .5rem 0;
  li:hover{
    a{
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
  a {
    color: #FFFFFF;
  }
}

/* 小屏收缩nav */
@media (max-width: 991.98px) {
  .navbar.layout-head-nav{
    padding: 1rem .8rem 1rem 1rem;
  }
  .navbar-toggler{
    margin-right: 0;
  }
  .navbar-nav .dropdown-menu.show{
    margin: auto;
    >li{
      text-align: center;
    }
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: .75rem;
  }
}
