$color: 'red';
$textColor: green;
$textHoverColor: greenyellow;
$textColor: paleturquoise;
$textHoverColor: aqua;
$textColor: red;
$textHoverColor: saddlebrown;

$navColors: (
  "default":  saddlebrown,
  "active":  red,
);

@mixin navColor($type) {
    color: $color;
}



.banner {
    $textColor: red;
    $textHoverColor: saddlebrown;
}
.scheme {
    $textColor: green;
    $textHoverColor: greenyellow;
}
// .seriousError {
//   @extend .error;
//   border-width: 3px;
// }


/*需要切换的颜色变量*/
$color-default:#1776E1; /* 更换的颜色 */
$color-active:#3588CB; /* 更换的颜色 */
$color-hover:#7D0000; /* 更换的颜色 */
$color-primary4:#EB6100; /* 更换的颜色 */

/*定义方法*/
@mixin nav_style($color){
    color:$color;
    /*判断匹配*/
    [data-theme="default"] & {
        color:$color-default;
    }
    [data-theme="active"] & {
        color:$color-active;
    }
    [data-theme="hover"] & {
        color:$color-hover;
    }  
    [data-theme="primary4"] & {
        color: $color-primary4;
    }
}


@mixin navAcitveStyle(
    $navText:#FFFFFF,
    $navCollapseBg: transparent,
    $linkHover: aqua,
    $itemBg: #000243,
    $linkTextColor: $navText
    ) {
    // background: $navCollapseBg;
    .logo-link{
        color: $navText;
    }
    button.navbar-toggler{
        color: $navText;
        border-color: $navText;
        &:focus{
            // outline: 1px auto $linkHover;
        }
        &:not(:focus-visible){
            // 静止状态
            // outline: 5px auto aqua;
            // outline: 1px auto $navText;
        }
    }
    .navbar-nav .dropdown-menu{
        background: $navCollapseBg;
    }


    .navbar-collapse.collapse {
        .navbar-nav {
            // padding: .5rem .875rem;
            background: $navCollapseBg;
            .nav-item {
                .nav-link{
                    color: $navText;
                    &:hover{
                        color: $linkHover;
                        // text-decoration: underline;
                        // text-underline-offset: 2px;
                    }
                }
            }
        }
    }

    .navbar-collapse.collapse.show {
        background: $navCollapseBg;
        // .nav-item{}
        .nav-item .nav-link{
            color: $linkTextColor;
            &:hover{
                color: $linkHover;
            }
        }
    }
}


@mixin arrowLine($color:#FFFFFF, $direction: bottom){
    position: relative;
    &:after{
		display: inline-block;
		margin: auto;
        margin-left: .2rem;
		box-sizing: border-box;
		width: .4rem;
		height: .4rem;
		border: solid $color;
        @if $direction == bottom{
            border-width: 0 0 1px 1px;
            transform: rotate(-45deg);
            vertical-align: 10%;
        }@else  if $direction==top{
            border-width: 1px 0 0 1px;
            transform: rotate(45deg);
            vertical-align: -4%;
        } 
    }
}
