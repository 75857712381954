.pc{
  .pc-hide{
    display: none !important;
  }
  .pc-show{
    display: block !important;
  }
}
.app-small{
  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
}

.content-fixed-w{
  margin: auto;
  width: 90%;
  max-width: 1440px;

}
.app-small .content-fixed-w, .mobile .content-fixed-w{
  max-width: initial;
  width: 100%;
}

.mt24{
	margin-top: 24px;
}
.mb16{
	margin-top: 16px;
}


.bg-1{
	background: #12122C;
}

.visible{
  visibility: visible;
}
 .block {
    display: block;
  }
  
  .pointer {
    cursor: pointer;
  }
  
  .inlineBlock {
    display: block;
  }
  
  .clearfix {
    &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }
  }
  .text-overflow{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-overflow-2row {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
 .text-overflow-3row {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
 

  // 去除元素滚动条--x方向
  .scrollbar-hidden-x{
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: auto;
    &:-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  .cursor-p{
    cursor: pointer;
  }

  // // 闪烁箭头
  // .blink-arrow {
  //     // 动画元素名-动画整个过程的时间
  //     animation: hzfirst .5s;
  //     // 动画次数
  //     animation-iteration-count: 3;
  // }
  // .card-item-wrap:hover {
	// 	.blink-arrow {
  //       opacity: 0.2;
	// 	}
	// }
  // @keyframes hzfirst {
  //   from {
  //     opacity: 1;
  //   }
  //   to {
  //     opacity: .2;
  //   }
  // }


  @keyframes hzfirst {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.2;
    }
  }



  // 图片hover闪烁效果1
  .imgHoverMask1 {
    mask: linear-gradient(135deg, #000c 40%, #000, #000c 60%) 100% 100%/240%
      240%;
    transition: 0.4s;
    cursor: pointer;
    &:hover {
      --i: -1;
      mask-position: 0 0;
    }
  }


  .imghover-xl{
    --perspective: 700px;
    --translateX: 10px;
    transition-duration: 0.3s;
		&:hover {
			// transform: perspective(500px) rotateY(8deg);
			transform: perspective(--perspective) translateX(--translateX)
		}
	}

	.imghover-scaleOut{
    --perspective: 700px;
    --scale: 1.2;
    transition-duration: 0.3s;
		&:hover {
			transform: perspective(var(--perspective)) scale(var(--scale));
		}
	}

	.imghover-xr{
    --perspective: 700px;
    --translateX: -10px;
    transition-duration: 0.3s;
		&:hover {
			// transform: perspective(500px) rotateY(-8deg);
			transform: perspective(--perspective) translateX(--translateX)
		}
	}

.consult-plus-btn{
    margin-top: 3rem;
    height: 3rem;
    line-height: 3rem;
    padding: 0 1rem 0 1.5rem;
    display: inline-block;
    color: #FFFFFF;
    cursor: pointer;
    background: #1300FF;
    font-family: PingFangSC-Medium, PingFang SC;
    .consult-blink-arr{
      margin-left: 3rem;
      color: #FFFFFF;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
    }
    &:hover .consult-blink-arr{
      opacity: 1;
      /*动画元素名-动画整个过程的时间*/
      animation: hzfirst .3s;
      /*动画次数*/
      animation-iteration-count: 3;
    }
}

.app-small .consult-plus-btn{

}

.ma-t2{
  margin-top: 2rem;
}
.ma-t5{
  margin-top: 5rem;
}

.pa-t5{
  padding-top: 5rem;
}

.pa-t5{
  padding-bottom: 5rem;
}

.pc{
  // 字号
  .fs0{
    font-size: 4rem !important;  // 64px
  }
  .fs1{
    font-size: 3.5rem !important;  // 56px
  }
  .fs2{
    font-size: 2.5rem !important;  // 40px
  }
  .fs3{
    font-size: 1.5rem !important;  // 24px
  }
  .fs4{
    font-size: 1.25rem !important;  // 20px
  }
  .fs5{
    font-size: 1rem !important;  // 16px
  }



  // 1号上边距: 2rem(32px) ~ 1rem(16px)
  .gap-mt1{
    margin-top: 2rem !important;
  }
  .gap-pt1{
    padding-top: 2rem !important;
  }
  // 1下边距: 2rem(32px) ~ 1rem(16px)
  .gap-mb1{
    margin-bottom: 2rem !important;
  }
  .gap-pb1{
    padding-bottom: 2rem !important;
  }
  // 1号左边距: 2rem(32px) ~ 1rem(16px)
  .gap-ml1{
    margin-left: 2rem !important;
  }
  .gap-pl1{
    padding-left: 2rem !important;
  }
  // 1号右边距: 2rem(32px) ~ 1rem(16px)
  .gap-mr1{
    margin-right: 2rem !important;
  }
  .gap-pr1{
    padding-right: 2rem !important;
  }
  

  // 2号上边距: 2.5rem(40px) ~ 1.25rem(20px)
  .gap-mt2{
    margin-top: 2.5rem !important;
  }
  .gap-pt2{
    padding-top: 2.5rem !important;
  }
  // 2号下边距:  2.5rem(40px) ~ 1.25rem(20px)
  .gap-mb2{
    margin-bottom: 2.5rem !important;
  }
  .gap-pb2{
    padding-bottom: 2.5rem !important;
  }



  // 3号上边距: 3rem(48px) ~ 1.5rem(24px)
  .gap-mt3{
    margin-top: 3rem !important;
  }
  .gap-pt3{
    padding-top: 3rem !important; 
  }
  // 3号下边距:  3rem(48px) ~ 1.5rem(24px)
  .gap-mb3{
    margin-bottom: 3rem !important;
  }
  .gap-pb3{
    margin-bottom: 3rem !important;
  }


  // 4号上边距: 5rem(80px) ~ 2rem(32px)
  .gap-mt4{
    margin-top: 5rem !important;
  }
  .gap-pt4{
    padding-top: 5rem !important; 
  }
  // 4号下边距:  5rem(80px) ~ 2rem(32px)
  .gap-mb4{
    margin-bottom: 5rem !important;
  }
  .gap-pb4{
    padding-bottom: 5rem !important;
  }


  // 5号上边距: 6.25rem(100px) ~ 2.5rem(40px)
  .gap-mt5{
    margin-top: 6.25rem !important;
  }
  .gap-pt5{
    padding-top: 6.25rem !important; 
  }
  // 5号下边距: 6.25rem(100px) ~ 2.5rem(40px)
  .gap-mb5{
    margin-bottom: 6.25rem !important;
  }
  .gap-pb5{
    padding-bottom: 6.25rem !important;
  }
  

  // 6号上边距: 7.5rem(120px) ~ 3rem(48px)
  .gap-mt6{
    margin-top: 7.5rem !important;
  }
  .gap-pt6{
    padding-top: 7.5rem !important; 
  }
  // 6号下边距: 7.5rem(120px) ~ 3 (48px)
  .gap-mb6{
    margin-bottom: 7.5rem !important;
  }
  .gap-pb6{
    padding-bottom: 7.5rem !important;
  }
        
}
.app-small{
  // 字号
  .fs0{
    font-size: 1.75rem !important;  // 28px
  }
  .fs1{
    font-size: 1.5rem !important;  // 524px
  }
  .fs2{
    font-size: 1.25rem !important;  // 20px
  }
  .fs3{
    font-size: 1rem !important;  // 16px
  }
  .fs4{
    font-size: 1rem !important;  // 16px
  }
  .fs5{
    font-size: .88rem !important;  // 14px
  }


  // 1号上边距: 2rem(32px) ~ 1rem(16px)
  .gap-mt1{
    margin-top: 1rem !important;
  }
  .gap-pt1{
    padding-top: 1rem !important;
  }
  // 1下边距: 2rem(32px) ~ 1rem(16px)
  .gap-mb1{
    margin-bottom: 1rem !important;
  }
  .gap-pb1{
    padding-bottom: 1rem !important;
  }
  // 1号左边距: 2rem(32px) ~ 1rem(16px)
  .gap-ml1{
    margin-left: 1rem !important;
  }
  .gap-pl1{
    padding-left: 1rem !important;
  }
  // 1号右边距: 2rem(32px) ~ 1rem(16px)
  .gap-mr1{
    margin-right: 1rem !important;
  }
  .gap-pr1{
    padding-right: 1rem !important;
  }



  // 2号上边距: 2.5rem(40px) ~ 1.25rem(20px)
  .gap-mt2{
    margin-top: 1.25rem !important;
  }
  .gap-pt2{
    padding-top: 1.25rem !important;
  }
  // 2号下边距:  2.5rem(40px) ~ 1.25rem(20px)
  .gap-mb2{
    margin-bottom: 1.25rem !important;
  }
  .gap-pb2{
    padding-bottom: 1.25rem !important;
  }

  
  // 3号上边距: 3rem(48px) ~ 1.5rem(24px)
  .gap-mt3{
    margin-top: 1.5rem !important; 
  }
  .gap-pt3{
    padding-top: 1.5rem !important;
  }
  // 3号下边距:  3rem(48px) ~ 1.5rem(24px)
  .gap-mb3{
    margin-bottom: 1.5rem !important;
  }
  .gap-pb3{
    margin-bottom: 1.5rem !important;
  }


  // 4号上边距: 5rem(80px) ~ 2rem(32px)
  .gap-mt4{
    margin-top: 2rem !important; 
  }
  .gap-pt4{
    padding-top: 2rem !important;
  }
  // 4号下边距:  5rem(80px) ~ 2rem(32px)
  .gap-mb4{
    margin-bottom: 2rem !important;
  }
  .gap-pb4{
    padding-bottom: 2rem !important;
  }


  // 5号上边距: 6.25rem(100px) ~ 2.5rem(40px)
  .gap-mt5{
    margin-top: 2.5rem !important; 
  }
  .gap-pt5{
    padding-top: 2.5rem !important;
  }
  // 5号下边距: 6.25rem(100px) ~ 2.5rem(40px)
  .gap-mb5{
    margin-bottom: 2.5rem !important;
  }
  .gap-pb5{
    padding-bottom: 2.5rem !important;
  }
  

  // 6号上边距: 7.5rem(120px) ~ 3rem(48px)
  .gap-mt6{
    margin-top: 3rem !important; 
  }
  .gap-pt6{
    padding-top: 3rem !important;
  }
  // 6号下边距: 7.5rem(120px) ~ 3 (48px)
  .gap-mb6{
    margin-bottom: 3rem !important;
  }
  .gap-pb6{
    padding-bottom: 3rem !important;
  }
}