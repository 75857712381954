
.reservation-service-btn {
}
.reservation-service-modal {
}

.modal-body-scroll{
    height: 70vh !important;
    overflow-y: auto;
}

.verify-image-modal{
    .modal-content{
        width: 500px;

    }
    .modal-body{

    }
}

