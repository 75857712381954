
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;

	overscroll-behavior: contain;
	
	[class$='_page']{
		text-align: justify;
		font-family: PingFangSC-Regular;
	}

}


