/* ------------------------- html字体尺寸 ------------------------- */
$pcFS: 100vw / (1440/16);			// pc端rem  使用: 实际尺寸*16 rem
// $pcFS: 16px;			// pc端rem  固定
// $pcMdFS: 100vw / (1080/16);			// pc端rem  使用: 实际尺寸*16 rem
$pcXsFS: 100vw / (750/16);			// pc端小屏rem 使用: 实际尺寸*16 rem
$mobileFS: 100vw / (375/16);		// 移动端rem 使用: 实际尺寸*16 rem
@mixin fontSize($size) {
	font-size: $size;
}
html{
	&.pc{
        @include fontSize($pcFS);
	}
	&.app-small{
        @include fontSize($pcXsFS);
	}
	&.mobile{
        @include fontSize($mobileFS);
	}
	@media (min-width: 1440px) {
		&.pc{
			@include fontSize(16px);
		}
	}
	// @media screen and (min-width: 1080) and (max-width: 1440) {
    //     @include fontSize($pcXsFS);
	// }
}

/* --------------------- 大小屏定义区间(不区分设备) --------------------- */
// // 小屏
// @media screen and (min-width: 300px) and (max-width: 992px) {
// 	html {
//         @include fontSize($mobileFS);
// 	}
// }
// // 大屏
// @media (min-width: 992px) {
// 	html {
//         @include fontSize($pcFS);
// 	}
// }

/* --------------------- 滚动条隐藏样式 --------------------- */
/* 滚动条隐藏样式---html */
@mixin htmlScrollbar {
	height: 100%;
	scrollbar-width:none;
	// overflow: -moz-hidden-unscrollable;
}
/* 滚动条隐藏样式---body */
@mixin bodyScrollbar {
	-ms-overflow-style: none;
	height: 100%;
	// width: calc(100vw + 1.125rem);
	overflow: auto;
	&::-webkit-scrollbar{
		width: 0;
		height: 0;;
		display: none;
	}
}

/* 屏幕尺寸分界点: 原设计pc768以下为小屏, 但是破坏栅格系统,且实际中pc屏幕在768~1080间的很少, 故将768(bootstrap的md屏幕尺寸下节点)作为最小宽度分界点, 上节点为1140, 而设计高给出pc最小高度为1080,所以这区间一些样式需要特殊设置  */
/* 屏幕768~1080区间外-样式设置 */
$pc-xs-top: 768px;
$pc-lg-bottom: 1080px;
@media not screen and (max-width:$pc-lg-bottom) and (min-width:$pc-xs-top){
	/* 默认隐藏滚动条 */
	// html {
	// 	@include htmlScrollbar;
	// 	body {
	// 		@include bodyScrollbar;
	// 		// background-color: pink;
	// 	}
	// }
}
/* 屏幕768~1080区间内-样式设置  */
@media screen and (min-width: $pc-xs-top) and (max-width: $pc-lg-bottom) {
	// .pc{
	// 	/* body设置最小宽度, 小于出现滚动条 */
	// 	body {
	// 		min-width: $pc-lg-bottom;
	// 		// min-width: 1140px;
	// 	}
	// 	.container-fiuled{
	// 		min-width: 100%;
	// 	}
	// }
	// .mobile{ }
	/* 不设置最小宽度 不出现滚动条(调试) */
	// html {
	// 	@include htmlScrollbar;
	// 	body {
	// 		@include bodyScrollbar;
	// 		// background-color: pink;
	// 	}
	// }
}

// // 小屏
@media (max-width: 992px) {
	html.mobile {
		@include htmlScrollbar;
		body {
			@include bodyScrollbar;
			// background-color: pink;
		}
	}
}


// html {
// 	overflow-y: scroll;
//   }
  
// :root {
// overflow-y: auto;
// overflow-x: hidden;
// }

// :root body {
// position: absolute;
// }

// body {
// width: 100vw;
// overflow: hidden;
// }

