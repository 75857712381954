
.toolbar-comp{
    position: fixed;
    right: 1rem;
    bottom: 6.25rem;
    width: 48px;
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    cursor: pointer;
    
    .reservation{
        height: 48px;
        background: #1300FF;
        color: #FFFFFF;
        box-shadow: 0px 4px 13px 0px rgba(18,18,44,0.08), 0px -4px 13px 0px rgba(0,0,0,0.08);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon{
            width: 24px;
            height: 24px;
            background: url('~@/assets/imgs/icon/message.png') no-repeat center center;
            background-size: cover;
        }
        .text{
            margin-top: 3px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 1;
            transform: scale(.75);
            width: max-content;
            display: inline-block;
        }
    }
    .gotop{
        height: 48px;
        background: #FFFFFF;
        box-shadow: 0rem 0.25rem 0.81rem 0rem rgba(18,18,44,0.08);
        display: none;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 24px;
            height: 24px;
            background: #FFFFFF url('~@/assets/imgs/icon/gotop.png') no-repeat center center;
            background-size: cover;
        }
        .scroll-overflow-screen &{
            display: block;
        }
    }

}
.app-small .toolbar-comp{
    right: .8rem;
    bottom: 5.63rem;
    height: 106px;

    .reservation{
        .text{
            display: none;
        }
    }
    .barbtn{
        border-radius: 50%;
    }
    .gotop{
        margin-top: 10px;
    }
}
