html,body{
	font-family: PingFang SC,Helvetica,Tahoma,Arial,Microsoft YaHei,Hiragino Sans GB,WenQuanYi Micro Hei,sans-serif;
}
html{
	background:#ffffff;
	margin: 0;
	touch-action: manipulation;
	-ms-touch-action: manipulation; // IE10
    .body{
        font-size: 100%;
		max-width: 100vw;
    }
}

p,ul li,ol li{
	margin:0;
	font-size:14px;
}
h1,h2,h3,h4,h5,h6{
	font-family: 'PingFangSC-Semibold';
	margin:0;
}
ul,label{
	margin:0;
	padding:0;
}
body a:hover{
	text-decoration:none;
}
input[type="submit"],input[type="reset"],a,.w3l_banner_bottom_grid1 i,.w3layouts_more a i{
	-webkit-transition: 0.5s ease-in;
    -moz-transition: 0.5s ease-in;
    -ms-transition: 0.5s ease-in;
    -o-transition: 0.5s ease-in;
    transition:0.5s ease-in;
}
a:focus{
	outline:none;
}
ul,li{
	list-style:none;
}



// html {
//     // overflow: -moz-hidden-unscrollable;
//     height: 100%;
// 	scrollbar-width:none;
// }

// body::-webkit-scrollbar {
//     display: none;
// }

// body {
//     -ms-overflow-style: none;
//     height: 100%;
// 	// width: calc(100vw + 18px);
// 	overflow: auto;
// }


@import "@/assets/style/adp.scss";
@import "@/assets/style/var.scss";
@import "@/assets/style/common.scss";
@import '@/assets/style/bs.scss';
@import '@/assets/style/pc.scss';
// @import '@/assets/style/mixin.scss';
